import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import App from "../components/app"

import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Bookmarktopia" />
    <div className="BasicPage">
      <App
        name="Bookmarktopia"
        imageName="/icon-bookmarktopia.png"
        isInBeta={true}
      />
    </div>
  </Layout>
)

export default Page
